import { Routes } from '@angular/router';
import { DashboardLayoutComponent } from '../../../shared/src/lib/components/layouts/dashboard-layout/dashboard-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'cifm',
        loadChildren: () => import('../../../admin/src/app/dashboard/intermediary-desk/cifm/cifm.routes'),
      },
      {
        path: 'intermediary-desk',
        loadChildren: () =>
          import('../../../admin/src/app/dashboard/intermediary-desk/intermediary-desk.routes'),
      },
      {
        path: 'ciin',
        loadChildren: () =>
          import('../../../admin/src/app/dashboard/intermediary-desk/ciin/ciin.routes'),
      },
      {
        path: 'set-ups',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../../../admin/src/app/dashboard/set-ups/parameters/parameters.routes'),
          },
          {
            path: 'agent-types',
            loadChildren: () =>
              import('../../../admin/src/app/dashboard/set-ups/agent-type/agent-type.routes'),
          },
          {
            path: 'companies',
            loadChildren: () =>
              import('../../../admin/src/app/dashboard/set-ups/companies/companies.routes').then((c) =>
                c.companyRoutes(false),
              ),
          },
          {
            path: 'users',
            loadChildren: () => import('../../../admin/src/app/dashboard/set-ups/users/users.routes'),
          },
          {
            path: 'user-groups',
            loadChildren: () =>
              import('../../../admin/src/app/dashboard/set-ups/user-groups/users-groups.routes'),
          },
        ],
      },
      {
        path: 'admin-desk',
        children: [
          {
            path: 'agent/fraud-investigation',
            data: { title: 'Agent Activation' },
            loadChildren: () =>
              import(
                '../../../admin/src/app/dashboard/admin/agent/agent-fraud-investigation/agent-fraud-investigation.routes'
              ),
          },
          {
            path: 'agent',
            children: [
              {
                path: 'migration',
                children: [
                  { path: '', redirectTo: 'staging', pathMatch: 'full' },
                  {
                    path: 'staging',
                    data: { title: 'Agent Staging Migration' },
                    loadComponent: () =>
                      import(
                        '../../../admin/src/app/dashboard/admin/agent/agent-staging-migration/agent-staging-migration.component'
                      ).then((r) => r.AgentStagingMigrationComponent),
                  },
                  {
                    path: 'candidate-ria',
                    data: { title: 'Candidate RIA Migration' },
                    loadComponent: () =>
                      import(
                        '../../../admin/src/app/dashboard/admin/agent/bulk-ria-candidates/bulk-ria-candidates.component'
                      ).then((c) => c.BulkRiaCandidatesComponent),
                  },
                  {
                    path: 'create-legacy',
                    data: { title: 'Save Legacy Agent' },
                    loadComponent: () =>
                      import(
                        '../../../admin/src/app/dashboard/admin/agent/save-legacy-agent/save-legacy-agent.component'
                      ).then((c) => c.SaveLegacyAgentComponent),
                  },
                ],
              },
              {
                path: 'view',
                data: { title: 'View Agent' },
                loadChildren: () =>
                  import(
                    '../../../admin/src/app/dashboard/intermediary-desk/tied-agents/view-tied-agent/view-tied-agent.routes'
                  ),
              },
            ],
          }, 
          {
            path: 'rrr/checker',
            data: { title: 'RRR Checker' },
            loadComponent: () => import('../../../admin/src/app/dashboard/admin/rrr-checker/rrr-checker.component'),
          },
          {
            path: 'batch',
            loadChildren: () =>
              import('../../../admin/src/app/dashboard/admin/batch-operations/batch-operations.routes'),
          },
          {
            path: 'documents',
            data: { title: 'Documents', fullOptions: true },
            loadComponent: () =>
              import('../../../shared/src/lib/components/document-listing/document-listing.component').then(
                (c) => c.DocumentListingComponent,
              ),
          },
          {
            path: 'user',
            children: [
              {
                path: 'activity',
                loadChildren: () =>
                  import('../../../shared/src/lib/components/user-activity/user-activity.module').then(
                    (m) => m.UserActivityModule,
                  ),
              },
            ],
          },
        ],
      },
      { path: '', redirectTo: 'cifm', pathMatch: 'full' },
    ],
  },
];
